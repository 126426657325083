<template>
    <div class="wrap">
      <img src="https://ai.modehoist.com/gowow/website/AlphaDetail/Vehicle.png" />
  
      <div class="box">
        <div class="title">
            Alpha
        </div>
        <ul class="smalltitle">
          <li class="active" @click="product">
            {{ $t("index.了解") }} Alpha
          </li>
          <li @click="gotrial">
            {{ $t("index.预约试驾") }} Alpha
          </li>
          <li @click="add" v-if="isCN">
            {{ $t("index.定购") }} Alpha
          </li>
        </ul>
      </div>
      <Float ref="Float" v-if="false" />
    </div>
  </template>
  
  <script>
  import { goto } from "@utils/goto";
  import Float from "../../product/Alpha/components/Float";
  import { mapState } from "vuex";
  
  export default {
    components: {
      Float,
    },
    computed: {
      ...mapState(["lang", "profile"]),
      isCN() {
        return this.lang === "zh-CN";
      },
    },
    methods: {
      gotrial() {
        goto("/trial");
      },
      add() {
        goto("/buy");
        // goto("/order/ProductDetails");
      },
      product() {
        goto("/product/Alpha");
      },
    },
  };
  </script>
  
  <style lang='scss' scoped>
  .wrap {
    position: relative;
    text-align: center;
    overflow: hidden;
  }
  
  img {
    width: 65%;
    margin-top: 16%;
  }
  img {
    vertical-align: top;
  }
  .box {
    position: absolute;
    top: 0;
    width: 100vw;
    text-align: center;
    padding-top: 30px;
    .title {
      font-family: PingFang-SC-Bold;
      font-weight: bold;
      font-size: 30px;
      line-height: 120px;
    }
    .smalltitle {
      width: 100%;
      justify-content: center;
      display: flex;
      li {
        margin: 0 7vw;
        font-size: 18px;
        line-height: 40px;
      }
      li:hover {
        cursor: pointer;
      }
      .active {
        border-bottom: 2px solid black;
      }
    }
  }
  </style>