<template>
  <div class="wrap">
    <img src="../../../assets/imgs/knowORI.png" />

    <div class="box">
      <div class="title">
        {{ $t("index.猎户座") }}-{{ $t("index.全地形电动摩托车") }}
      </div>
      <ul class="smalltitle">
        <li class="active" @click="product">
          {{ $t("index.了解") }} {{ $t("index.猎户座") }}
        </li>
        <li @click="gotrial">
          {{ $t("index.预约试驾") }} {{ $t("index.猎户座") }}
        </li>
        <li @click="add" v-if="isCN">
          {{ $t("index.定购") }} {{ $t("index.猎户座") }}
        </li>
      </ul>
    </div>
    <Float ref="Float" v-if="false" />
  </div>
</template>

<script>
import { goto } from "@utils/goto";
import Float from "../../product/ORI/components/Float";
import { mapState } from "vuex";

export default {
  components: {
    Float,
  },
  computed: {
    ...mapState(["lang", "profile"]),
    isCN() {
      return this.lang === "zh-CN";
    },
  },
  methods: {
    gotrial() {
      goto("/trial");
    },
    add() {
      goto("/buy");
      // goto("/order/ProductDetails");
    },
    product() {
      goto("/product/ORI");
    },
  },
};
</script>

<style lang='scss' scoped>
.wrap {
  position: relative;
}

img {
  width: 100%;
}
img {
  vertical-align: top;
}
.box {
  position: absolute;
  top: 0;
  width: 100vw;
  text-align: center;
  padding-top: 30px;
  .title {
    font-family: PingFang-SC-Bold;
    font-weight: bold;
    font-size: 30px;
    line-height: 120px;
  }
  .smalltitle {
    width: 100%;
    justify-content: center;
    display: flex;
    li {
      margin: 0 7vw;
      font-size: 18px;
      line-height: 40px;
    }
    li:hover {
      cursor: pointer;
    }
    .active {
      border-bottom: 2px solid black;
    }
  }
}
</style>