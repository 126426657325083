<template>
  <div class="wrap">
    <div class="bgimg">
      <img src="@assets/imgs/homeM/backgroundimg.png" />
    </div>
    <div class="content">
      <div class="section">
        <!-- <div>GOWOW ORI</div> -->
      </div>
      <div class="section">
        <div>{{ $t("index.全地形新能源摩托车 阿尔法") }}</div>
      </div>
      <div class="btn">
        <div class="see-video-btn" @click="product">
          {{ $t("index.了解详情") }}
        </div>
        &nbsp;
        <div class="see-video-btn" @click="gotrial">
          {{ $t("index.预约试驾") }}
        </div>
      </div>
    </div>
  </div>
</template>
        
<script>
import { goto } from "@utils/goto";
export default {
  data() {
    return {};
  },
  methods: {
    product() {
      goto("/product/ORI");
    },
    gotrial() {
      goto("/trial");
    },
  },
};
</script>

   <style lang='scss' scoped>
@import "~@assets/styles/vars.scss";
@import "~@assets/styles/mixin.scss";
@import "~@assets/styles/func.scss";
* {
  padding: 0;
  margin: 0;
}
img {
  vertical-align: top;
}
.wrap {
  position: relative;
}
.btn {
  display: flex;
  justify-content: center;
}
.content {
  position: absolute;
  top: 55%;
  color: #ffffff;
  width: 100%;
  text-align: center;

  .section {
    &:nth-of-type(1) {
      font-family: PingFang-SC-Medium;
      font-size: px2rem(50px);
      line-height: px2rem(60px);
    }
    &:nth-of-type(2) {
      font-family: PingFang-SC-Medium;
      font-size: px2rem(20px);
      line-height: px2rem(30px);
      font-weight: 200;
    }
  }
  .see-video-btn {
    cursor: pointer;
    margin-top: px2rem(20px);
    width: px2rem(120px);
    height: px2rem(40px);
    line-height: px2rem(35px);
    border: 2px solid rgba(255, 255, 255, 1);
    font-size: px2rem(16px);
    font-weight: 200;
    font-family: PingFang-SC-Medium;
    border-radius: px2rem(20px);
  }
}
.bgimg {
  img {
    width: 100%;
  }
}
</style>